<template>
  <div class="wrapper">
    <h4>修改用户信息</h4>
    <el-form :model="form" ref="form" :rules="rules" label-width="150px">
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="用户ID" prop="userId">
            <el-input v-model="form.userId" auto-complete="off" :disabled="true"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="登录用户名" prop="userLoginName">
            <el-input v-model="form.userLoginName" auto-complete="off" :disabled="true"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="真实姓名" prop="realName">
            <el-input v-model="form.realName" placeholder="" @input="change($event)" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="性别" prop="sex">
            <el-input v-model="form.sex" placeholder="" @input="change($event)" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="图像" prop="userImgs">
            <upload-img :limit="1" :uploadLists="imgList" @uploadChange="uploadSuccess1" :before-upload="beforeAvatarUpload"></upload-img>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="抬头" prop="relateEnvId">
            <el-input v-model="form.relateEnvId" placeholder="请选择抬头" auto-complete="off" :disabled="true">
              <el-button slot="append" icon="el-icon-search" @click="selectPersonId()"></el-button>
            </el-input>
          </el-form-item>
        </el-col>
         <el-col :span="8">
             <el-form-item label="主页抬头" prop="mainTitle">
            <el-input v-model="form.mainTitle" placeholder="主页抬头" auto-complete="off" :disabled="true">
            </el-input>
          </el-form-item>
         </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="扩展字段1" prop="col1">
            <el-input v-model="form.col1" auto-complete="off" :disabled="true"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="扩展字段2" prop="col2">
            <el-input v-model="form.col2" auto-complete="off" :disabled="true"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="扩展字段3" prop="col3">
            <el-input v-model="form.col3" auto-complete="off" :disabled="true"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="扩展字段4" prop="col4">
            <el-input v-model="form.col4" auto-complete="off" :disabled="true"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <el-form-item style="text-align: center" label-width="0">
            <el-button type="primary" icon="fa fa-check" @click="submitEvent('form')">保存</el-button>
            <el-button icon="fa fa-undo" @click="$router.go(-1)">返回</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-dialog title="选择人员" :visible.sync="visible" width="50%" :close-on-click-modal="false">
      <div>
         <vxe-form title-align="right" title-width="120" title-colon :data="searchData" @submit="search" @reset="searchReset">
        <vxe-form-item title="数据分区Id" span="12">
          <vxe-input v-model="searchData.id" placeholder="请输入数据分区Id" clearable></vxe-input>
        </vxe-form-item>
        <vxe-form-item title="主页面标题" span="12">
          <vxe-input v-model="searchData.mainPageTitle" placeholder="请输入主页面标题" clearable></vxe-input>
        </vxe-form-item>
        <vxe-form-item align="center" span="12">
          <vxe-button type="submit" status="my-orange" icon="fa fa-check">搜索</vxe-button>
          <vxe-button type="reset" status="my-orange" icon="fa fa-undo">重置</vxe-button>
        </vxe-form-item>
      </vxe-form>
        <div style="margin-top:10px;">
          <el-table :data="tableData" ref="singleTable" highlight-current-row border size="medium" :height="height">
            <el-table-column label="" width="40">
              <template slot-scope="scope">
                <el-radio
                  :label="scope.$index"
                  v-model="radio"
                  @change.native="getCurrentRow(scope.row)"
                  style="color: #fff;padding-left: 10px; "
                ></el-radio>
              </template>
            </el-table-column>
            <el-table-column prop="id" width="100" label="数据分区Id" align="center"></el-table-column>
            <el-table-column prop="mainPageTitle" label="主页标题" width="100" align="center"></el-table-column>
            <el-table-column prop="col1" label="扩展字段1" width="100" align="center"></el-table-column>
            <el-table-column prop="col2" label="扩展字段2" width="100" align="center"></el-table-column>
            <el-table-column prop="col3" label="扩展字段3" width="100" align="center"></el-table-column>
            <el-table-column prop="col4" label="扩展字段4" width="100" align="center"></el-table-column>
            <el-table-column prop="col5" label="扩展字段5" align="center"></el-table-column>
          </el-table>
          <div style="text-align: right; margin-top: 10px;">
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="tablePage.currentPage"
              :page-sizes="[50, 100, 500]"
              :page-size="tablePage.pageSize"
              layout="total,jumper,prev, pager, next,sizes"
              :total="tablePage.totalResult"
            ></el-pagination>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel()">取 消</el-button>
        <el-button type="primary" @click="cancel()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import UploadImg from '@/components/UploadImg.vue'
import axios from 'axios'
import api from '@/store/API/api'
export default {
  name: 'EditUser',
  components: {
     UploadImg,
   },
  data() {
    return {
      radio: '',
      height: 240,
      tableData: [],
      tablePage: {
        totalResult: 0,
        currentPage: 1,
        pageSize: 10,
        align: 'left',
        pageSizes: [10, 20, 50, 100, 500, 1000],
        perfect: true,
      },
      visible: false,
      searchData: {
        id: '',
        mainPageTitle:'',
      },
      form: {},
      imgList: [],
        rules: {
        realName: [{ required: true, message: '请输入真实姓名', trigger: 'blur' }],
      }
    }
  },
  created() {
    this.initView()
  },
  methods: {
    ...mapActions(['getGlobalEvn', 'editUserInfo']),
    getCurrentRow(row) {
      this.form.personId = row.id
      this.form.tel = row.tel
      this.form.idNum = row.idNum
      this.form.sex = row.sex
      this.form.name = row.name
      this.form.relateEnvId = row.id
      this.form.mainTitle = row.mainPageTitle
      this.form.col1 = row.col1
      this.form.col2 = row.col2
      this.form.col3 = row.col3
      this.form.col4 = row.col4
    },
    cancel() {
      this.visible = false
    },
    search() {
      this.getConsConsultant()
    },
    change(e) {
      this.$forceUpdate()
    },
    handleSizeChange: function(size) {
      this.tablePage.pageSize = size
      this.getConsConsultant()
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 6;
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过5MB!');
        return
      }
    },
    // 控制页面的切换
    handleCurrentChange: function(currentPage) {
      this.tablePage.currentPage = currentPage
      this.getConsConsultant()
    },
    getConsConsultant() {
      let params = {
        current: this.tablePage.currentPage,
        size: this.tablePage.pageSize,
        param: {
          id: this.searchData.id,
          mainPageTitle: this.searchData.mainPageTitle,
        },
      }
      this.getGlobalEvn(params).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data.records
          this.tablePage.totalResult = +res.data.total
        }
      })
    },
    initView() {
      let itemData = JSON.parse(this.$route.query.item);
      console.log(itemData)
      this.form.userId = itemData.id
      this.form.userLoginName = itemData.loginName
      this.form.realName = itemData.descrption
      this.form.sex = itemData.sex
      this.form.userImgs = itemData.photoId
      this.form.relateEnvId = itemData.relateEnvId
      this.form.mainTitle = itemData.mainPageTitle
      this.form.col1 = itemData.col1
      this.form.col2 = itemData.col2
      this.form.col3 = itemData.col3
      this.form.col4 = itemData.col4
      if(itemData.photoId){
        this.getFileDetail1(itemData.photoId)
      }

      this.getConsConsultant()
    },
      getFileDetail1(ids) {
      ids = ids.split(',')
      console.log(ids)
      ids.forEach((item) => {
        axios({
          method: 'get',
          data: {},
          url: '/api' + api.fileDetail + '/' + item,
          responseType: 'blob',
          headers: {
            Authorization: sessionStorage.token,
          },
        }).then((res) => {
          this.imgList.push({
            id: item,
            url: window.URL.createObjectURL(res.data),
          })
        })
      })
    },
    uploadSuccess1(data) {
      this.form.imgList = data.join(',')
    },
    submitEvent(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {  
          let params = {
            ...this.form,
          }
          if(this.form.imgList){
            params.userImgs = this.form.imgList
          }
          let server = 'editUserInfo'
          console.log(params)
          this[server](params).then((res) => {
            if (res.code == 200) {
              this.$XModal.message({
                message:  '添加成功',
                status: 'success',
              })
              this.$router.go(-1)
            }
            this.loading = false
          })
        } else {
          return false
        }
      })
    },
    selectPersonId() {
      this.visible = true
    },
    searchReset() {
      this.searchData = {}
      this.getConsConsultant()
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 10px;
  overflow: hidden;
  h4 {
    height: 40px;
    line-height: 40px;
    color: #333;
    padding-left: 20px;
    margin-bottom: 20px;
  }
}
</style>
